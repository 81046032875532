"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";

const Bronze = css`
  overflow-y: auto;
  max-height: 90vh;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  padding-bottom: 24px;
`;

const Silver = css``;

const Gold = css`
  gap: 8px;
  margin-top: 8px;
  flex-direction: row;
`;

const Platinum = css``;

const Diamond = css``;

export const SPageHeaderSectionsAndNewsletter = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
