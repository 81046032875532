"use client";
import styled, { css } from "styled-components";

const Bronze = css`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

export const SAssetLoader = styled.div`
  ${Bronze}
`;
