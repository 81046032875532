"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import { SButton } from "../../../button/styles/SButton";

const Bronze = css`
  background: rgba(0, 0, 0, 0.65);
  border: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(80px);
  border-radius: 12px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${SButton} {
    width: 100%;
  }
`;

const Silver = css``;

const Gold = css`
  flex-grow: 1;
  margin-top: 0;
  max-height: 420px;
  padding-bottom: 12px;
  overflow-y: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const Platinum = css``;

const Diamond = css`
  max-height: 664px;
`;

export const SPageHeaderSections = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
