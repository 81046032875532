"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";

interface ISPageHeader {
  $isSticky?: boolean;
}

const CSSSticky = css`
  padding: 12px;
`;

const CSSDiamondSticky = css`
  padding: 20px;
`;

const Bronze = css<ISPageHeader>`
  max-width: 1920px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;
  width: 100%;
  padding: 0;
  transition: padding 0.3s ease-in-out;
  ${({ $isSticky }) => ($isSticky ? CSSSticky : "")};
  a {
    cursor: pointer;
  }
`;

const Silver = css<ISPageHeader>``;

const Gold = css<ISPageHeader>``;

const Platinum = css<ISPageHeader>``;

const Diamond = css<ISPageHeader>`
  ${({ $isSticky }) => ($isSticky ? CSSDiamondSticky : "")};
`;

export const SPageHeader = styled.nav<ISPageHeader>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
