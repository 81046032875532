"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import { SButtonPromo } from "../../../button/styles/SButtonPromo";

interface ISPageHeaderNavRightLoginAndTry {
  $isSticky?: boolean;
}

const CSSSticky = css`
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  width: fit-content;
  background-image: var(--blue-gradient);
`;

const Bronze = css<ISPageHeaderNavRightLoginAndTry>``;

const Silver = css<ISPageHeaderNavRightLoginAndTry>``;

const Gold = css<ISPageHeaderNavRightLoginAndTry>`
  display: flex;
  align-items: center;
  margin-left: 24px;
  gap: 8px;

  ${SButtonPromo} {
    p {
      ${({ $isSticky }) => ($isSticky ? CSSSticky : "")}
    }
  }
`;

const Platinum = css<ISPageHeaderNavRightLoginAndTry>``;

const Diamond = css<ISPageHeaderNavRightLoginAndTry>``;

export const SPageHeaderNavRightLoginAndTry = styled.div<ISPageHeaderNavRightLoginAndTry>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
