"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import { SButton } from "../../../button/styles/SButton";
import { SPageHeaderPageHeaderTopStyleDropdownHeader } from "./SPageHeaderPageHeaderTopStyleDropdownHeader";

const Bronze = css``;

const Silver = css``;

const Gold = css<{ $isSticky?: boolean }>`
  a {
    width: 100%;
  }
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(80px);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 465px;
  margin-top: 38px;
  padding-bottom: 11px;
  max-height: 420px;
  overflow-y: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  ${SButton} {
    width: 100%;
  }

  ${SPageHeaderPageHeaderTopStyleDropdownHeader} {
    margin-bottom: 0;
  }
  ${(props) => props.$isSticky && "background:#10223d"};
`;

const Platinum = css``;

const Diamond = css`
  margin-top: 34px;
  max-height: 664px;
`;

export const SPageHeaderTopStyleDropdownWrapper = styled.div<{
  $isSticky?: boolean;
}>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
