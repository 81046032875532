"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import { CSSCaption01SemiXLarge } from "../../../../constants/styles/typographies/CSSTypographies";

const Bronze = css``;

const Silver = css``;

const Gold = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-02);
`;

const Platinum = css``;

const Diamond = css`
  ${CSSCaption01SemiXLarge};
`;

export const SPageHeaderNavRightContactBtn = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
