"use client";
import styled, { css, keyframes } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";

type ISPageHeaderNavRightMainLinks = {
  $isSticky?: boolean;
  $hasSession?: boolean;
};

const textFadeIn = keyframes`
  0% { opacity:0;}
  100%{ opacity:1;}
    `;

const positionWithSession = css`
  transform: translate(-130px, -43px);
`;
const positionWithoutSession = css`
  transform: translate(-242px, -43px);
`;

const isStickyCSS = css<ISPageHeaderNavRightMainLinks>`
  text-align: left;
  display: flex;
  justify-content: flex-end;
  animation: ${textFadeIn} 0s ease-in-out 0.3s forwards;
  opacity: 0;
  ${(props) =>
    props.$hasSession ? positionWithSession : positionWithoutSession};
  @media (min-width: 1920px) {
    transform: ${(props) =>
      props.$hasSession
        ? "translate(-158px, -53px)"
        : "translate(-290px, -53px)"};
  }
`;

const Bronze = css``;

const Silver = css``;

const Gold = css<ISPageHeaderNavRightMainLinks>`
  display: flex;
  gap: 16px;
  align-items: center;

  &::after {
    content: "";
    width: 1px;
    height: 12px;
    background: rgba(255, 255, 255, 0.2);
    margin: 0 4px 0 4px;
  }
  ${(props) => props.$isSticky && isStickyCSS}
`;

const Platinum = css`
  gap: 24px;
`;

const Diamond = css``;

export const SPageHeaderNavRightMainLinks = styled.div<ISPageHeaderNavRightMainLinks>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
