"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import { CSSHighlight } from "../../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  ${CSSHighlight};
  text-transform: uppercase;
  color: var(--text-10);
  display: none;
`;

const Silver = css``;

const Gold = css`
  display: block;
  padding: 23px 23px 0;
`;

const Platinum = css``;

const Diamond = css``;

export const SPageHeaderSectionTitle = styled.p`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
