"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import { SPageHeaderLogoCont } from "./SPageHeaderLogoCont";

interface ISPageHeaderCont {
  $isSticky?: boolean;
}

const CSSSticky = css`
  background: rgba(0, 0, 0, 0.65);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: var(--big-elevation);
  border-radius: 12px;
  padding: 15px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
`;

const CSSDiamondSticky = css`
  background: rgba(0, 0, 0, 0.65);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: var(--big-elevation);
  border-radius: 12px;
  padding: 15px;
  padding-left: 23px;
`;

const Bronze = css<ISPageHeaderCont>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 24px;
  transition-property: background-color, padding;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  ${({ $isSticky }) => ($isSticky ? CSSSticky : "")};
`;

const Silver = css<ISPageHeaderCont>``;

const Gold = css<ISPageHeaderCont>`
  padding: 40px 52px;

  & > a ${SPageHeaderLogoCont} {
    display: none;
  }

  ${({ $isSticky }) => $isSticky && CSSDiamondSticky};
  background: ${({ $isSticky }) => !$isSticky && "transparent"};
`;

const Platinum = css<ISPageHeaderCont>``;

const Diamond = css<ISPageHeaderCont>`
  padding: 34px 76px;
  ${({ $isSticky }) => ($isSticky ? CSSDiamondSticky : "")};
`;

export const SPageHeaderCont = styled.div<ISPageHeaderCont>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
