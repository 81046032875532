"use client";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { NextImage } from "src/components/next-image/NextImage";
import { ChevronDown } from "../../../svgs/ChevronDown";
import { ChevronLeft } from "../../../svgs/ChevronLeft";
import { ChevronRight } from "../../../svgs/ChevronRight";
import { Close } from "../../../svgs/Close";
import { Menu } from "../../../svgs/Menu";
import useOnClickOutside from "../../../utils/helpers/useOnClickOutside";
import { IGenMegaMenu, IGenUniversalText } from "../../../utils/types_gen";
import { Button } from "../../button/Button";
import { SPageHeader } from "./styles/SPageHeader";
import { SPageHeaderCont } from "./styles/SPageHeaderCont";
import { SPageHeaderContSectionTitle } from "./styles/SPageHeaderContSectionTitle";
import { SPageHeaderIcon } from "./styles/SPageHeaderIcon";
import { SPageHeaderLink } from "./styles/SPageHeaderLink";
import { SPageHeaderLinkArrow } from "./styles/SPageHeaderLinkArrow";
import { SPageHeaderLinkIcon } from "./styles/SPageHeaderLinkIcon";
import { SPageHeaderLinkText } from "./styles/SPageHeaderLinkText";
import { SPageHeaderLogo } from "./styles/SPageHeaderLogo";
import { SPageHeaderLogoCont } from "./styles/SPageHeaderLogoCont";
import { SPageHeaderLogoVersion } from "./styles/SPageHeaderLogoVersion";
import { SPageHeaderNav } from "./styles/SPageHeaderNav";
import { SPageHeaderNavLink } from "./styles/SPageHeaderNavLink";
import { SPageHeaderNavRight } from "./styles/SPageHeaderNavRight";
import { SPageHeaderNavRightContactBtn } from "./styles/SPageHeaderNavRightContactBtn";
import { SPageHeaderNavRightLoginAndTry } from "./styles/SPageHeaderNavRightLoginAndTry";
import { SPageHeaderNavRightMainLinks } from "./styles/SPageHeaderNavRightMainLinks";
import { SPageHeaderSections } from "./styles/SPageHeaderSections";
import { SPageHeaderSectionsAndNewsletter } from "./styles/SPageHeaderSectionsAndNewsletter";
import { SPageHeaderSectionsWrapper } from "./styles/SPageHeaderSectionsWrapper";
import { SPageHeaderSectionTitle } from "./styles/SPageHeaderSectionTitle";
import { SPageHeaderTopStyleDropdownWrapper } from "./styles/SPageHeaderTopStyleDropdownWrapper";
import { SPageHeaderPageHeaderTopStyleDropdownHeader } from "./styles/SPageHeaderPageHeaderTopStyleDropdownHeader";
import Link from "next/link";
import { getEnvUrlPart } from "../../../utils/helpers/getEnvUrlPart";
import { getCookie } from "../../../utils/helpers/getCookie";
import { SAssetLoader } from "./styles/SAssetLoader";

interface IPageHeader {
  navigation?: IGenMegaMenu;
}

export const PageHeader: React.FC<
  IPageHeader & { homePageSlug?: string; universalText: IGenUniversalText }
> = ({
  navigation: {
    logo,
    mainNavigation,
    siteVersion,
    contactSales,
    login,
    tryCaisy,
  },
  universalText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const { goToApp } = universalText || {};
  const [hasSession, setHasSession] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (getCookie("caisyuserid")) {
        setHasSession(true);
      }
    }
  }, []);

  const headerRef = useRef<HTMLDivElement>();
  useOnClickOutside(headerRef, () => {
    setActiveSection("");
    setIsOpen(false);
  });
  const sections = useMemo(
    () =>
      mainNavigation?.reduce((acc, section) => {
        if (
          section?.__typename === "NavigationSection" &&
          section.links.length > 1
        ) {
          acc[section.id] = section;
        }
        return acc;
      }, {}),
    []
  );

  React.useEffect(() => {
    if (typeof window === "undefined") return null;
    const handleStickyHeader = () => {
      window.scrollY > 0 ? setIsSticky(true) : setIsSticky(false);
    };
    const mobileViewHandler = () => {
      window.innerWidth < 1280 ? setIsMobile(true) : setIsMobile(false);
    };
    mobileViewHandler();
    handleStickyHeader();
    window.addEventListener("resize", mobileViewHandler);
    window.addEventListener("scroll", handleStickyHeader);
    return () => {
      window.removeEventListener("scroll", handleStickyHeader);
      window.removeEventListener("resize", mobileViewHandler);
    };
  }, []);

  const handleHeaderMenu = () => {
    setIsOpen(!isOpen);
    setActiveSection("");
  };
  return (
    <SPageHeader
      $isSticky={isSticky || (isOpen && isMobile)}
      ref={headerRef}
      onMouseLeave={() => {
        setActiveSection("");
      }}
    >
      <SAssetLoader>
        {mainNavigation?.map((section) =>
          section?.links?.map((x, ix) => (
            <Fragment key={ix}>
              <NextImage
                alt={x?.icon?.description || x?.icon?.title}
                src={x?.icon?.src}
                fill
              />
            </Fragment>
          ))
        )}
      </SAssetLoader>

      <SPageHeaderCont $isSticky={isSticky || (isOpen && isMobile)}>
        <SPageHeaderNav>
          <Link href="/" passHref aria-label="Go to home" title="Go to home">
            <SPageHeaderLogoCont>
              {logo?.src && (
                <SPageHeaderLogo>
                  <NextImage
                    src={logo.src}
                    fill
                    alt={logo.description || logo.title}
                  />
                </SPageHeaderLogo>
              )}
              {siteVersion && (
                <SPageHeaderLogoVersion $isSticky={isSticky || isOpen}>
                  <p>{siteVersion}</p>
                </SPageHeaderLogoVersion>
              )}
            </SPageHeaderLogoCont>
          </Link>
          <SPageHeaderNavRight>
            <SPageHeaderNavRightMainLinks>
              {mainNavigation?.map((section) =>
                section?.__typename === "NavigationSection" ? (
                  section.links.length > 1 ? (
                    <Button
                      key={section.id}
                      disableStyle
                      onClick={() => {
                        setIsOpen(section.id !== activeSection);
                        setActiveSection(
                          section.id === activeSection ? "" : section.id
                        );
                      }}
                      style={{ position: "relative" }}
                    >
                      <SPageHeaderNavLink
                        $isActive={section.id === activeSection}
                        $isSticky={isSticky}
                        $isSection={true}
                        onMouseEnter={() => {
                          setActiveSection(section.id);
                        }}
                      >
                        <p>{section.sectionTitle}</p>
                        <ChevronDown />
                      </SPageHeaderNavLink>
                      {!isSticky && activeSection === section.id && (
                        <SPageHeaderTopStyleDropdownWrapper>
                          <SPageHeaderPageHeaderTopStyleDropdownHeader>
                            {section.sectionTitle}
                          </SPageHeaderPageHeaderTopStyleDropdownHeader>
                          {activeSection &&
                            sections[activeSection]?.links.map(
                              (link, index) => (
                                <Button
                                  key={link.id}
                                  {...link}
                                  disableStyle
                                  onClick={handleHeaderMenu}
                                >
                                  <SPageHeaderLink
                                    $isLast={
                                      index ===
                                      sections[activeSection]?.links.length - 1
                                    }
                                  >
                                    {link.icon?.src && (
                                      <SPageHeaderLinkIcon>
                                        <NextImage
                                          alt={
                                            link.icon.description ||
                                            link.icon.title
                                          }
                                          src={link.icon.src}
                                          fill
                                        />
                                      </SPageHeaderLinkIcon>
                                    )}

                                    <SPageHeaderLinkText>
                                      {link.title}
                                    </SPageHeaderLinkText>
                                    <SPageHeaderLinkArrow>
                                      <ChevronRight />
                                    </SPageHeaderLinkArrow>
                                  </SPageHeaderLink>
                                </Button>
                              )
                            )}
                        </SPageHeaderTopStyleDropdownWrapper>
                      )}
                    </Button>
                  ) : (
                    section.links[0]?.__typename === "Button" && (
                      <Button
                        key={section.id}
                        {...section.links[0]}
                        disableStyle
                        onClick={handleHeaderMenu}
                      >
                        <SPageHeaderNavLink
                          $isSticky={isSticky}
                          onMouseEnter={() => {
                            setActiveSection("");
                          }}
                        >
                          <p>{section.links[0].title}</p>
                        </SPageHeaderNavLink>
                      </Button>
                    )
                  )
                ) : (
                  ""
                )
              )}
            </SPageHeaderNavRightMainLinks>
            {contactSales && (
              <Button {...contactSales} disableStyle>
                <SPageHeaderNavRightContactBtn>
                  {contactSales.title}
                </SPageHeaderNavRightContactBtn>
              </Button>
            )}
            <SPageHeaderNavRightLoginAndTry $isSticky={isSticky || isOpen}>
              {hasSession ? (
                <Button
                  buttonStyle={"promo"}
                  shape={"Small"}
                  linkTarget="_self"
                  url={`https://app.${getEnvUrlPart()}caisy.io/app/profile/membership`}
                  title={goToApp}
                >
                  {goToApp}
                </Button>
              ) : (
                <>
                  {login && (
                    <Button
                      {...{
                        ...login,
                        linkTarget: "_self",
                        url: `https://app.${getEnvUrlPart()}caisy.io/app/login`,
                      }}
                    >
                      {login.title}
                    </Button>
                  )}
                  {tryCaisy && <Button {...tryCaisy}>{tryCaisy.title}</Button>}
                </>
              )}
            </SPageHeaderNavRightLoginAndTry>
          </SPageHeaderNavRight>
        </SPageHeaderNav>
        {activeSection ? (
          <>
            <SPageHeaderIcon
              onClick={() => {
                setActiveSection("");
              }}
            >
              <ChevronLeft />
            </SPageHeaderIcon>
            <SPageHeaderContSectionTitle>
              {sections[activeSection]?.sectionTitle}
            </SPageHeaderContSectionTitle>
          </>
        ) : (
          <Link href="/">
            <SPageHeaderLogoCont>
              {logo?.src && (
                <SPageHeaderLogo>
                  <NextImage
                    src={logo.src}
                    alt={logo.description || logo.title}
                    fill
                  />
                </SPageHeaderLogo>
              )}
              {siteVersion && (
                <SPageHeaderLogoVersion $isSticky={isSticky || isOpen}>
                  <p>{siteVersion}</p>
                </SPageHeaderLogoVersion>
              )}
            </SPageHeaderLogoCont>
          </Link>
        )}
        <SPageHeaderIcon onClick={handleHeaderMenu}>
          {isOpen ? <Close /> : <Menu />}
        </SPageHeaderIcon>
      </SPageHeaderCont>
      {isSticky && !isMobile ? (
        // {isSticky && activeSection && !isMobile ? (
        <>
          {/* <SPageHeaderSections> */}
          <SPageHeaderNavRightMainLinks
            $isSticky={isSticky}
            $hasSession={hasSession}
          >
            {mainNavigation?.map((section) =>
              section?.__typename === "NavigationSection" ? (
                section.links.length > 1 ? (
                  <Button
                    key={section.id}
                    disableStyle
                    onClick={() => {
                      setIsOpen(section.id !== activeSection);
                      setActiveSection(
                        section.id === activeSection ? "" : section.id
                      );
                    }}
                    style={{ position: "relative" }}
                  >
                    <SPageHeaderNavLink
                      $isActive={section.id === activeSection}
                      $isSticky={isSticky}
                      $isSection={true}
                      onMouseEnter={() => {
                        setActiveSection(section.id);
                      }}
                      style={{ color: "transparent" }}
                    >
                      <p>{section.sectionTitle}</p>
                      <ChevronDown />
                    </SPageHeaderNavLink>
                    {isSticky && activeSection === section.id && (
                      <SPageHeaderTopStyleDropdownWrapper $isSticky={isSticky}>
                        <SPageHeaderPageHeaderTopStyleDropdownHeader>
                          {section.sectionTitle}
                        </SPageHeaderPageHeaderTopStyleDropdownHeader>
                        {activeSection &&
                          sections[activeSection]?.links.map((link, index) => (
                            <Button
                              key={link.id}
                              {...link}
                              disableStyle
                              onClick={handleHeaderMenu}
                            >
                              <SPageHeaderLink
                                $isLast={
                                  index ===
                                  sections[activeSection]?.links.length - 1
                                }
                              >
                                {link.icon?.src && (
                                  <SPageHeaderLinkIcon>
                                    <NextImage
                                      alt={
                                        link.icon.description || link.icon.title
                                      }
                                      src={link.icon.src}
                                      fill
                                    />
                                  </SPageHeaderLinkIcon>
                                )}

                                <SPageHeaderLinkText>
                                  {link.title}
                                </SPageHeaderLinkText>
                                <SPageHeaderLinkArrow>
                                  <ChevronRight />
                                </SPageHeaderLinkArrow>
                              </SPageHeaderLink>
                            </Button>
                          ))}
                      </SPageHeaderTopStyleDropdownWrapper>
                    )}
                  </Button>
                ) : (
                  section.links[0]?.__typename === "Button" && (
                    <Button
                      key={section.id}
                      {...section.links[0]}
                      disableStyle
                      onClick={handleHeaderMenu}
                    >
                      <SPageHeaderNavLink
                        $isSticky={isSticky}
                        onMouseEnter={() => {
                          setActiveSection("");
                        }}
                      >
                        <p style={{ color: "transparent" }}>
                          {section.links[0].title}
                        </p>
                      </SPageHeaderNavLink>
                    </Button>
                  )
                )
              ) : (
                ""
              )
            )}
          </SPageHeaderNavRightMainLinks>
          {/* </SPageHeaderSections> */}
          {/* <SPageHeaderNewsletter>
            {updates_icon?.src && (
              <SPageHeaderNewsletterIcon>
                <NextImage src={updates_icon.src} fill />
              </SPageHeaderNewsletterIcon>
            )}
            {updates_title && (
              <SPageHeaderNewsletterTitle>
                {updates_title}
              </SPageHeaderNewsletterTitle>
            )}
            {updates_description && (
              <SPageHeaderNewsletterDesc>
                <RichText content={updates_description.content} />
              </SPageHeaderNewsletterDesc>
            )}
            <SPageHeaderNewsletterSubscribeForm>
              <SPageHeaderNewsletterSubscribeInputCont>
                <SPageHeaderNewsletterSubscribeInput
                  type="email"
                  placeholder={updates_inputPlaceholder || ""}
                  required
                />
                <SPageHeaderNewsletterSubscribeButton>
                  <Button {...updates_subscribe}>
                    {updates_subscribe.title}
                  </Button>
                </SPageHeaderNewsletterSubscribeButton>
              </SPageHeaderNewsletterSubscribeInputCont>
            </SPageHeaderNewsletterSubscribeForm>
          </SPageHeaderNewsletter> */}
        </>
      ) : (
        isOpen &&
        isMobile && (
          <SPageHeaderSectionsAndNewsletter>
            <SPageHeaderSections>
              <SPageHeaderSectionsWrapper>
                {!activeSection &&
                  mainNavigation?.map((section, index) =>
                    section.__typename === "NavigationSection" ? (
                      section.links.length > 1 ? (
                        <Button
                          key={section.id}
                          disableStyle
                          onClick={() => {
                            setActiveSection(section.id);
                          }}
                        >
                          <SPageHeaderLink
                            $isLast={index === mainNavigation.length - 1}
                          >
                            <SPageHeaderLinkText>
                              {section.sectionTitle}
                            </SPageHeaderLinkText>
                            <SPageHeaderLinkArrow>
                              <ChevronRight />
                            </SPageHeaderLinkArrow>
                          </SPageHeaderLink>
                        </Button>
                      ) : (
                        section.links[0].__typename === "Button" && (
                          <Button
                            key={section.id}
                            {...section.links[0]}
                            disableStyle
                            onClick={handleHeaderMenu}
                          >
                            <SPageHeaderLink
                              $isLast={index === mainNavigation.length - 1}
                            >
                              <SPageHeaderLinkText>
                                {section.links[0].title}
                              </SPageHeaderLinkText>
                              <SPageHeaderLinkArrow>
                                <ChevronRight />
                              </SPageHeaderLinkArrow>
                            </SPageHeaderLink>
                          </Button>
                        )
                      )
                    ) : (
                      ""
                    )
                  )}
              </SPageHeaderSectionsWrapper>
              {activeSection && (
                <SPageHeaderSectionTitle>
                  {sections[activeSection]?.sectionTitle}
                </SPageHeaderSectionTitle>
              )}
              {activeSection &&
                sections[activeSection]?.links.map((link, index) => (
                  <Button
                    key={link.id}
                    {...link}
                    disableStyle
                    onClick={handleHeaderMenu}
                  >
                    <SPageHeaderLink
                      $isLast={
                        index === sections[activeSection]?.links.length - 1
                      }
                    >
                      {link.icon?.src && (
                        <SPageHeaderLinkIcon>
                          <NextImage
                            src={link.icon.src}
                            alt={link.icon.description || link.icon.title}
                            fill
                          />
                        </SPageHeaderLinkIcon>
                      )}
                      <SPageHeaderLinkText>{link.title}</SPageHeaderLinkText>
                      <SPageHeaderLinkArrow>
                        <ChevronRight />
                      </SPageHeaderLinkArrow>
                    </SPageHeaderLink>
                  </Button>
                ))}
            </SPageHeaderSections>
            {/* <SPageHeaderNewsletter>
              {updates_icon?.src && (
                <SPageHeaderNewsletterIcon>
                  <NextImage src={updates_icon.src} fill />
                </SPageHeaderNewsletterIcon>
              )}
              {updates_title && (
                <SPageHeaderNewsletterTitle>
                  {updates_title}
                </SPageHeaderNewsletterTitle>
              )}
              {updates_description && (
                <SPageHeaderNewsletterDesc>
                  <RichText content={updates_description.content} />
                </SPageHeaderNewsletterDesc>
              )}
              <SPageHeaderNewsletterSubscribeForm>
                <SPageHeaderNewsletterSubscribeInputCont>
                  <SPageHeaderNewsletterSubscribeInput
                    type="email"
                    placeholder={updates_inputPlaceholder || ""}
                    required
                  />
                  <SPageHeaderNewsletterSubscribeButton>
                    <Button {...updates_subscribe}>
                      {updates_subscribe.title}
                    </Button>
                  </SPageHeaderNewsletterSubscribeButton>
                </SPageHeaderNewsletterSubscribeInputCont>
              </SPageHeaderNewsletterSubscribeForm>
            </SPageHeaderNewsletter> */}
          </SPageHeaderSectionsAndNewsletter>
        )
      )}
    </SPageHeader>
  );
};
