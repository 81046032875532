"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";

interface ISPageHeaderLogoVersion {
  $isSticky?: boolean;
}

const CSSSticky = css`
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  width: fit-content;
  background-image: var(--blue-gradient);
`;

const Bronze = css<ISPageHeaderLogoVersion>`
  padding: 2px 5px;
  background: #ffffff;
  border-radius: 12px;
  font-weight: 800;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  p {
    color: var(--text-01);
    ${({ $isSticky }) => ($isSticky ? CSSSticky : "")}
  }
`;

const Silver = css<ISPageHeaderLogoVersion>``;

const Gold = css<ISPageHeaderLogoVersion>``;

const Platinum = css<ISPageHeaderLogoVersion>``;

const Diamond = css<ISPageHeaderLogoVersion>`
  padding: 4px 8px;
`;

export const SPageHeaderLogoVersion = styled.div<ISPageHeaderLogoVersion>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
