"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import {
  CSSCaption01Semi,
  CSSCaption01SemiXLarge,
} from "../../../../constants/styles/typographies/CSSTypographies";

interface ISPageHeaderNavLink {
  $isActive?: boolean;
  $isSticky?: boolean;
  $isSection?: boolean;
}

const CSSActive = css`
  color: var(--text-02);

  svg {
    transform: rotate(-180deg);
  }
`;

const Bronze = css<ISPageHeaderNavLink>``;

const Silver = css<ISPageHeaderNavLink>``;

const Gold = css<ISPageHeaderNavLink>`
  display: flex;
  gap: 2px;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.75);
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &:hover {
    color: var(--text-02);
  }

  ${({ $isActive }) => $isActive && CSSActive};
  ${({ $isSticky }) => !$isSticky && CSSCaption01Semi};
  ${({ $isSection }) => $isSection && `margin-top: 1px;`};
`;

const Platinum = css<ISPageHeaderNavLink>`
  gap: 2px;
`;

const Diamond = css<ISPageHeaderNavLink>`
  gap: 4px;
  ${CSSCaption01SemiXLarge};
  ${({ $isSection }) => $isSection && `margin-top: 0;`};
`;

export const SPageHeaderNavLink = styled.div<ISPageHeaderNavLink>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
