"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import {
  CSSSubtitle01Semi,
  CSSTitle01,
} from "../../../../constants/styles/typographies/CSSTypographies";

interface ISPageHeaderLink {
  $isLast?: boolean;
}

const Bronze = css<ISPageHeaderLink>`
  ${CSSSubtitle01Semi};
  color: var(--text-02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 15px 11px 15px 19px;
  border-bottom: ${({ $isLast }) =>
    $isLast ? "0" : "1px solid rgba(255, 255, 255, 0.1)"};

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

const Silver = css<ISPageHeaderLink>``;

const Gold = css<ISPageHeaderLink>`
  gap: 16px;
  padding: 16px 23px 15px;
  justify-content: unset;
  ${CSSTitle01};
  color: rgba(255, 255, 255, 0.75);
`;

const Platinum = css<ISPageHeaderLink>``;

const Diamond = css<ISPageHeaderLink>``;

export const SPageHeaderLink = styled.div<ISPageHeaderLink>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
