export const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.53 7.47a.75.75 0 00-1.06 1.06L10.94 12l-3.47 3.47a.75.75 0 101.06 1.06L12 13.06l3.47 3.47a.75.75 0 101.06-1.06L13.06 12l3.47-3.47a.75.75 0 00-1.06-1.06L12 10.94 8.53 7.47z"
      clipRule="evenodd"
    />
  </svg>
);
